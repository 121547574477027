import React, { useRef, useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuList from '@mui/material/MenuList';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import VisibilitySensor from 'react-visibility-sensor';

import Logo from './assets/logo.svg';
import './App.css';
import './Animation.css';
import Machine_video from './assets/middn_animation.webm';
//import Machine from './assets/machine.png';
import MachineCut from './assets/machine_cut.png';
import Sec1 from './assets/sec1.svg';
import Sec2 from './assets/sec2.svg';
import Sec3 from './assets/sec3.svg';
import Step1 from './assets/step1.png';
import Step2 from './assets/step2.png';
import Step3 from './assets/step3.png';
import Step4 from './assets/step4.png';
import Sec4 from './assets/sec4.svg';
import Sec5 from './assets/sec5.svg';
import Sec6 from './assets/sec6.svg';
import Slider from "react-slick";



import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
const sections = ['Home', 'How it work', 'Contact us'];



function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}


const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Eudoxus Sans',
    },
  },
});

const lineDuration = 1000;
const iconDuration = 1000;


export default function HideAppBar(props) {


	const myRef1 = useRef(null);
	const myRef2 = useRef(null);
	const myRef3 = useRef(null);

  const [drawer, setDrawer] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
	const [stepAnimatedLaunched, setStepAnimatedLaunched] = useState(false);

	const [selected, setSelected] = useState(sections[0]);

	const setSection = (section) => {
		setDrawer(false);
		setSelected(section);
		if(section === sections[0]) {
			myRef1.current.scrollIntoView();
		}
		if(section === sections[1]) {
			myRef2.current.scrollIntoView();
		}
		if(section === sections[2]) {
			myRef3.current.scrollIntoView();
		}
	}

  const gotNewSection = (section, isVisible) => {
    if(isVisible) {
      setSelected(section);
    }
    /*if(section === sections[0]) {
      setStepAnimatedLaunched(false);
    }*/
  }

  const startStepsAnimation = () => {
    if(stepAnimatedLaunched) {
      return;
    }
    setStepAnimatedLaunched(true);

    document.getElementById("step1img").classList.remove("shaker");
    document.getElementById("step2img").classList.remove("bouncer");
    document.getElementById("step3img").classList.remove("bouncer");
    document.getElementById("step4img").classList.remove("shaker");

    document.getElementById("step1img_").classList.remove("shaker");
    document.getElementById("step2img_").classList.remove("bouncer");
    document.getElementById("step3img_").classList.remove("bouncer");
    document.getElementById("step4img_").classList.remove("shaker");

    setTimeout(() => {
      animate();
    }, 500);
  }

  const animate = () => {

    const shift = 100;

    document.getElementById("step1img").classList.add("shaker");
    document.getElementById("step1img_").classList.add("shaker");
    
    setTimeout(() => {

      document.getElementById('an1').beginElement();
      document.getElementById('an1_').beginElement();

      setTimeout(() => {

        document.getElementById("step2img").classList.add("bouncer");
        document.getElementById("step2img_").classList.add("bouncer");

        setTimeout(() => {

          document.getElementById('an2').beginElement();
          document.getElementById('an2_').beginElement();

          setTimeout(() => {

            document.getElementById("step3img").classList.add("bouncer");
            document.getElementById("step3img_").classList.add("bouncer");

            setTimeout(() => {

              document.getElementById('an3').beginElement();
              document.getElementById('an3_').beginElement();

              setTimeout(() => {

                  document.getElementById("step4img").classList.add("shaker");
                  document.getElementById("step4img_").classList.add("shaker");

              }, lineDuration+shift);

            }, iconDuration+shift);

          }, lineDuration+shift);

        }, iconDuration+shift);

      }, lineDuration+shift);

    }, iconDuration+shift);

  }

  const [divPosition, setDivPosition] = useState(0);
  const divRef = useRef();
  const gridRef = useRef();
  const handleScroll = () => {
    if(!divRef || !gridRef) {
      return;
    }
    const position = window.pageYOffset;
    const divTop = divRef.current?.offsetTop;
    const divBottom = divTop + divRef.current?.clientHeight;
    const divMiddle = (divTop + divBottom)/2;
    const delta = divMiddle - position;
    const compar = Math.min(window.innerHeight, divBottom);
    var q, el, animation, timing;
    if(window.innerHeight < 900) {
      q = 1;
    } else {
      q = 1.2;
    }
    let nextDivPosition = 0;
    if(delta < q*compar/3) {
      nextDivPosition = 2;
    } else if(delta >= q*compar/3 && delta < 2*compar/3) {
      nextDivPosition = 1;
    } else {
      nextDivPosition = 0;
    }
    setDivPosition(nextDivPosition);

    if(window.innerWidth < 900) {
      el = document.getElementById("bg_home_");
      animation = [
        { transform: 'translateY('+getComputedStyle(el).top+'px)' },
        { transform: 'translateY('+nextDivPosition*gridRef.current?.clientHeight+'px)' }
      ];
      timing = {
        duration: 1000,
        iterations: 1,
        fill: 'forwards',
        easing: 'linear'
      };
    } else {
      el = document.getElementById("bg_home");
      animation = [
        { left: getComputedStyle(el).left },
        { left: nextDivPosition*33.333333+'%' }
      ];
      timing = {
        duration: 200,
        iterations: 1,
        fill: 'forwards',
        easing: 'linear'
      };
    }
    el.animate(animation, timing);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);






  var settings = {
    infinite: false,
    speed: 500,
    arrows: false,
    dots: true,
    dotsClass: "button__bar",
  };

	return (

    <div className="portal__container">
    	<ThemeProvider theme={theme}>

        <HideOnScroll {...props}>
      	<AppBar style={{backgroundColor: 'rgb(19, 20, 22)', paddingTop: 27, paddingBottom: 37, borderBottom: "0.5px solid #2B2B2B"}}>
      		<Container style={{ maxWidth: '1440px'}} disableGutters maxWidth="xl">
      			
      			<Grid container>
      				
      				<Grid item xs={1} md={12*100/1440}></Grid>
      				<Grid item xs={10} md={12*1240/1440}>

      					<Toolbar style={{justifyContent: 'space-between'}} disableGutters variant="dense">

      						<Box
      							sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}
                    style={{marginTop: 8}}
      						>
      							<img style={{height: '35%'}} src={Logo} alt="logo" />
      						</Box>


      						<Box
      							noWrap
      							component="div"
      							sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
      						>
      							<img src={Logo} alt="logo" />
      						</Box>

                  <Box style={{position: 'absolute', width: '100%'}} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
        						<Box sx={{ width: 100*(336/1099)+'%', display: { xs: 'none', md: 'flex' }, justifyContent: 'space-around' }}>
        							{sections.map((section, index) => (
          								<Button
          									key={"sec_"+index}
          									onClick={() => setSection(section)}
          									sx={{ padding: 0, minHeight: 0, minWidth: 0, color: 'white', display: 'block', textTransform: 'none'}}
          								>
          									<Typography style={{fontFamily: 'Inter', fontWeight: '600'}} variant="body1" textAlign="center">{section}</Typography>
                            { selected === section ? <div className="dot_container"><div className="dot"></div></div> : null }
          								</Button>
        							))}
                    </Box>
      						</Box>

      						<Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, }}>
                    <a href="https:\\tally.so\r\wLDxWv" className="gotoapp_button" style={{textDecoration: 'none'}}>
                      <Typography variant="body1" style={{fontWeight: '900', color: '#131517'}}>Join our ico</Typography>
                    </a>
      						</Box>

                  <Box sx={{ flexGrow: 0, display: { md: 'none', xs: 'flex' }, }}>
                    <IconButton
                      onClick={() => setDrawer(true)}
                      color="inherit"
                    >
                      {drawer ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                  </Box>

      					</Toolbar>

      				</Grid>
      				<Grid item xs={1} md={12*100/1440}></Grid>

      			</Grid>
      		</Container>
      	</AppBar>
        </HideOnScroll>

        <Toolbar />
        <Toolbar />

        <SwipeableDrawer
          anchor={"left"}
          open={drawer}
          onClose={() => setDrawer(false)}
          onOpen={() => setDrawer(true)}
          PaperProps={{
            sx: {
              backgroundColor: "#18191D",
              color: "white",
              justifyContent: 'center',
              minWidth: "80%"
            },
          }}
        >

          <div className="drawer_bg" style={{height: '100%'}}>
            <div className="drawer_inner">
              <MenuList disableGutters>
                {sections.map((section, index) => (
                  <div key={index}>
                    <MenuItem onClick={() => setSection(section)}>
                        <Typography align="center" style={{width: '100%', fontWeight: (selected === section ? 'bold' : 'none'), color: 'black'}} variant="h5" textAlign="center">{section}</Typography>
                    </MenuItem>
                  </div>
                ))}
                <MenuItem style={{paddingLeft: '10px', paddingRight: '10px'}} disableGutters>
                  <a href="https:\\tally.so\r\wLDxWv" className="gotoapp_button black_bg_button" style={{textDecoration: 'none', width: '100%'}}>
                    <Typography align="center" variant="body1" style={{width: '100%', fontWeight: '900', color: 'white'}}>Join our ico</Typography>
                  </a>
                </MenuItem>
              </MenuList>
            </div>
          </div>

        </SwipeableDrawer>

        <div>

          <Grid sx={{ display: { xs: 'none', md: 'flex' } }} style={{maxWidth: "1440px", zIndex: -1, position: 'absolute'}} container>

            <Grid item xs={1} sm={1} md={12*100/1440}>
            </Grid>

            <Grid item xs={10} sm={10} md={12*((1440-905)/1440) - 12*100/1440}>
            </Grid>


            <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12} sm={12} md={12*905/1440}>
              { /*videoLoaded ? null : <img style={{width: '100%'}} src={Machine} alt="machine" />*/ }
              <video style={{position: 'relative', bottom: 15, width: '80%'}}/*style={{width: videoLoaded ? '80%' : '0%'}}*/ onLoadedData={() => setVideoLoaded(true)} autoPlay loop muted><source src={Machine_video} type='video/mp4' /></video>
            </Grid>

          </Grid>

          <VisibilitySensor onChange={(isVisible) => { gotNewSection(sections[0], isVisible); }} >
          <Grid sx={{ marginTop: { xs: '35px', md: '0px' } }} container ref={myRef1}>

            <Grid item xs={1} sm={1} md={12*100/1440}>
            </Grid>

            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} item xs={10} sm={10} md={12*(605/1440)}>

              <div>

                <Typography sx={{ display: { xs: 'none', md: 'flex' } }} variant="h2" style={{ fontSize: '64px', letterSpacing: '-0.03em', paddingTop: 84, lineHeight: '91px', fontWeight: '700', color: 'white'}}>The third-party Escrow protection</Typography>
                
                <Typography sx={{ display: { xs: 'flex', md: 'none' } }} variant="h4" style={{ letterSpacing: '-0.03px', paddingTop: 0, lineHeight: '60px', fontWeight: '700', color: 'white'}}>The third-party Escrow protection</Typography>

                <div style={{height: 25}}></div>

                <Typography variant="description" style={{ color: '#ffffffed', fontFamily: 'Eudoxus Sans', fontWeight: 300, fontSize: '16px', lineHeight: '29px', letterSpacing: '-0.01em'}}>We help you secure your online trades by offering a secure and reliable P2P transaction using escrow</Typography>
                
                <Container disableGutters sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'start' } }}>

                  <a href="https:\\tally.so\r\wLDxWv" className="gotoapp_button" style={{marginTop: 50, marginRight: 12.5, textDecoration: 'none'}}>
                    <Typography variant="subtitle1" style={{letterSpacing: '-0.02px', fontWeight: 'bold', color: '#131517'}}>Join our ico</Typography>
                  </a>

                  <a href="https:\\drive.google.com\file\d\1hidf-xbkosxlnj3FllsgFExqQ4nvJr1U\view" className="learnmore_button" style={{marginLeft: 12.5, marginTop: 50, textDecoration: 'none'}}>
                    <Typography variant="subtitle1" style={{letterSpacing: '-0.02px', fontWeight: 'bold', color: 'white'}}>Whitepaper</Typography>
                  </a>

                </Container>


              </div>

            </Grid>


            <Grid item xs={12} sm={12} md={12*((1440-100-605)/1440)}>
            </Grid>

          </Grid>
          </VisibilitySensor>

          <Grid sx={{ marginTop: '60px', display: { xs: 'flex', md: 'none' } }} container>

            <Grid item xs={1} sm={1} md={0}>
            </Grid>

            <Grid item xs={10} sm={10} md={12}>
              <img style={{width: '100%'}} src={MachineCut} alt="machine" />
            </Grid>

            <Grid item xs={1} sm={1} md={0}>
            </Grid>

          </Grid>








          <Grid ref={divRef} container sx={{ marginTop: { xs: '40px', md: '128px' } }}>

            <Grid item xs={1} sm={1} md={0.83}>
            </Grid>

            <Grid style={{borderRadius: 30, backgroundColor: '#18191D', paddingTop: 30, paddingBottom: 23, border: "1px solid #23262F"}} item xs={10} sm={10} md={12-0.83*2}>

              <Grid container>
                
                <Container disableGutters sx={{ display: { xs: 'none', md: 'flex' } }} style={{maxWidth: '1240px', width: 100*((12-0.83*2)/12)+'%', position: 'absolute'}}>
                  
                  <div id="bg_home" style={{position: 'relative', width: '33.333333333333333333%'}}>
                    <div id="bg_container" style={{paddingLeft: 7, paddingRight: 7}}>
                      <div style={{backgroundColor: '#202227', borderRadius: 30, paddingBottom: 23, paddingRight: 30, paddingLeft: 30, paddingTop: 24}}>
                        <img style={{opacity: 0, width: 54, height: 54, backgroundColor: '#3772FF', borderRadius: 200}} src={Sec3} alt="FinalizationIcon" />
                        <div style={{height: 22}}></div>
                        <Typography variant="h6" style={{opacity: 0, letterSpacing: '-0.03px', lineHeight: "25.2px", fontWeight: 'bold', color: 'blue'}}>Finalize The Escrow</Typography>
                        <div style={{height: 16}}></div>
                        <Typography variant="body2" style={{opacity: 0, letterSpacing: '-0.02px', lineHeight: '27px', fontSize: '14px', fontWeight: '500', color: '#FFFFFF'}}>Once all parties involved have signed the contract, Middn can trigger the finalization of the escrow and transfer the fund between the parties.</Typography>
                      </div>
                    </div>
                  </div>

                </Container>


                <Container disableGutters sx={{ display: { xs: 'flex', md: 'none' } }} style={{position: 'absolute', maxWidth: '1240px', width: 100*(10/12)+'%'}}>
                    
                    <div id="bg_home_" style={{position: 'relative', width: '100%'}}>
                      <div id="bg_container_" style={{paddingLeft: 7, paddingRight: 7}}>
                        <div style={{backgroundColor: '#202227', borderRadius: 30, paddingBottom: 23, paddingRight: 30, paddingLeft: 30, paddingTop: 24}}>
                          <img style={{opacity: 0, width: 54, height: 54, backgroundColor: '#3772FF', borderRadius: 200}} src={Sec3} alt="FinalizationIcon" />
                          <div style={{height: 22}}></div>
                          <Typography variant="h6" style={{opacity: 0, letterSpacing: '-0.03px', lineHeight: "25.2px", fontWeight: 'bold', color: 'white'}}>Finalize The Escrow</Typography>
                          <div style={{height: 16}}></div>
                          <Typography variant="body2" style={{opacity: 0, letterSpacing: '-0.02px', lineHeight: '27px', fontSize: '14px', fontWeight: '500', color: '#FFFFFF'}}>Once all parties involved have signed the contract, Middn can trigger the finalization of the escrow and transfer the fund between the parties.</Typography>
                        </div>
                      </div>
                    </div>

                </Container>


                <Grid sx={{ paddingRight: { xs: '14px', md: '7px' } }} style={{opacity: 1, zIndex: 1, paddingLeft: 14}} item xs={12} sm={12} md={12*413.33/1240}>
                  <div style={{paddingBottom: 23, paddingRight: 30, paddingLeft: 30, paddingTop: 24}}>
                    <img style={{width: 54, height: 54, backgroundColor: '#45B26B', borderRadius: 200}} src={Sec1} alt="WalletIcon" />
                    <div style={{height: 22}}></div>
                    <Typography variant="h6" style={{letterSpacing: '-0.02em', lineHeight: "25.2px", fontWeight: '500', color: 'white'}}>Escrow Creation</Typography>
                    <div style={{height: 16}}></div>
                    <Typography variant="body2" style={{opacity: 0.45, letterSpacing: '-0.02px', lineHeight: '27px', fontSize: '14px', fontWeight: '500', color: '#FFFFFF'}}>Create an escrow contract by specifying the terms of the transaction, the amount and the information.</Typography>
                  </div>
                </Grid>

                <Grid sx={{ paddingLeft: { xs: '14px', md: '7px' }, paddingRight: { xs: '14px', md: '7px' } }} style={{opacity: 1, zIndex: 1}} item xs={12} sm={12} md={12*413.34/1240}>
                  <div style={{paddingBottom: 23, paddingRight: 30, paddingLeft: 30, paddingTop: 24}}>
                    <img style={{width: 54, height: 54, backgroundColor: '#9757D7', borderRadius: 200}} src={Sec2} alt="SharingIcon" />
                    <div style={{height: 22}}></div>
                    <Typography variant="h6" style={{letterSpacing: '-0.02em', lineHeight: "25.2px", fontWeight: '500', color: 'white'}}>Escrow Sharing</Typography>
                    <div style={{height: 16}}></div>
                    <Typography variant="body2" style={{opacity: 0.45, letterSpacing: '-0.02px', lineHeight: '27px', fontSize: '14px', fontWeight: '500', color: '#FFFFFF'}}>Provides a secure link to share the escrow contract with other parties involved so they can sign and validate it.</Typography>
                  </div>
                </Grid>

                <Grid ref={gridRef} sx={{ paddingLeft: { xs: '14px', md: '7px' } }} style={{opacity: 1, zIndex: 1, paddingRight: 14}} item xs={12} sm={12} md={12*413.33/1240}>
                  <div style={{paddingBottom: 23, paddingRight: 30, paddingLeft: 30, paddingTop: 24}}>
                    <img style={{width: 54, height: 54, backgroundColor: '#3772FF', borderRadius: 200}} src={Sec3} alt="FinalizationIcon" />
                    <div style={{height: 22}}></div>
                    <Typography variant="h6" style={{letterSpacing: '-0.02em', lineHeight: "25.2px", fontWeight: '500', color: 'white'}}>Finalize The Escrow</Typography>
                    <div style={{height: 16}}></div>
                    <Typography variant="body2" style={{opacity: 0.45, letterSpacing: '-0.02px', lineHeight: '27px', fontSize: '14px', fontWeight: '500', color: '#FFFFFF'}}>Once all parties involved have signed the contract, Middn can trigger the finalization of the escrow and transfer the fund between the parties.</Typography>
                  </div>
                </Grid>


              </Grid>

            </Grid>

            <Grid item xs={1} sm={1} md={0.83}>
            </Grid>

          </Grid>






          <Grid sx={{marginTop: { xs: '100px', md: '142px' }}}  container>

            <Grid item xs={1} sm={1} md={12*448/1440}>
            </Grid>

            <Grid item xs={10} sm={10} md={12*544/1440}>
              <VisibilitySensor onChange={(isVisible) => { gotNewSection(sections[1], isVisible); }} >
              <Typography ref={myRef2} variant="h3" align="center" style={{letterSpacing: '-0.02px', fontWeight: '700', color: 'white', lineHeight: '56px'}}>How it work</Typography>
              </VisibilitySensor>
              <Typography variant="body1" style={{textAlign: "center", marginTop: 22, color: '#E6E8EC', lineHeight: '24px'}}>A simple and user-friendly process explained in four steps.</Typography>      
            </Grid>

            <Grid item xs={1} sm={1} md={12*448/1440}>
            </Grid>

          </Grid>


          <Grid container>

            <div style={{ maxWidth: "1440px", width: '100%', position: 'absolute' }}>

              <Grid sx={{ width: '100%', flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }} container>
                
                <Grid item xs={0} sm={0} md={12*346/1440}></Grid>

                <Grid item xs={0} sm={0} md={12*160/1440}>
                  <div style={{height: 80, marginTop: 90, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <svg id="line1" width="160" height="12" viewBox="0 0 160 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    
                      <defs>
                        <mask id="msk">
                          <rect width="100%" height="100%" fill="white" />
                          <rect fill="black" x="-160" y="0" width="160" height="12" >
                            <animate id="an1" attributeName="x" begin="indefinite" dur={(lineDuration/1000).toFixed(2)+"s"} values="-160;160" repeatCount="0" fill="black" />
                          </rect>
                        </mask>
                      </defs>

                      <g id="path">
                        <line x1="17" y1="5.5" x2="140" y2="5.5" stroke="rgba(127,252,141,1)" strokeDasharray="4 8"/>
                        <circle cx="154" cy="6" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                        <circle cx="6" cy="6" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                      </g>
                      <g mask="url(#msk)">
                        <line x1="17" y1="5.5" x2="140" y2="5.5" stroke="#B1B5C4" strokeDasharray="4 8"/>
                        <circle cx="154" cy="6" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                        <circle cx="6" cy="6" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                      </g>

                    </svg>

                  </div>
                </Grid>
                
                <Grid item xs={0} sm={0} md={12*134/1440}></Grid>

                <Grid item xs={0} sm={0} md={12*160/1440}>
                  <div style={{height: 80, marginTop: 90, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <svg id="line2" width="160" height="12" viewBox="0 0 160 12" fill="none" xmlns="http://www.w3.org/2000/svg">

                      <defs>
                        <mask id="msk2">
                          <rect width="100%" height="100%" fill="white" />
                          <rect fill="black" x="-160" y="0" width="160" height="12" >
                            <animate id="an2" attributeName="x" begin="indefinite" dur={(lineDuration/1000).toFixed(2)+"s"} values="-160;160" repeatCount="0" fill="black" />
                          </rect>
                        </mask>
                      </defs>

                      <g id="path2">
                        <line x1="17" y1="5.5" x2="140" y2="5.5" stroke="rgba(127,252,141,1)" strokeDasharray="4 8"/>
                        <circle cx="154" cy="6" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                        <circle cx="6" cy="6" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                      </g>
                      <g mask="url(#msk2)">
                        <line x1="17" y1="5.5" x2="140" y2="5.5" stroke="#B1B5C4" strokeDasharray="4 8"/>
                        <circle cx="154" cy="6" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                        <circle cx="6" cy="6" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                      </g>

                    </svg>

                  </div>
                </Grid>
                
                <Grid item xs={0} sm={0} md={12*134/1440}></Grid>

                <Grid item xs={0} sm={0} md={12*160/1440}>
                  <div style={{height: 80, marginTop: 90, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <svg id="line3" width="160" height="12" viewBox="0 0 160 12" fill="none" xmlns="http://www.w3.org/2000/svg">

                      <defs>
                        <mask id="msk3">
                          <rect width="100%" height="100%" fill="white" />
                          <rect fill="black" x="-160" y="0" width="160" height="12" >
                            <animate id="an3" attributeName="x" begin="indefinite" dur={(lineDuration/1000).toFixed(2)+"s"} values="-160;160" repeatCount="0" fill="black" />
                          </rect>
                        </mask>
                      </defs>

                      <g id="path3">
                        <line x1="17" y1="5.5" x2="140" y2="5.5" stroke="rgba(127,252,141,1)" strokeDasharray="4 8"/>
                        <circle cx="154" cy="6" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                        <circle cx="6" cy="6" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                      </g>
                      <g mask="url(#msk3)">
                        <line x1="17" y1="5.5" x2="140" y2="5.5" stroke="#B1B5C4" strokeDasharray="4 8"/>
                        <circle cx="154" cy="6" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                        <circle cx="6" cy="6" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                      </g>

                    </svg>

                  </div>
                </Grid>

                <Grid item xs={0} sm={0} md={12*346/1440}></Grid>

              </Grid>

            </div>


            <Grid item xs={1} sm={1} md={1}></Grid>

            <Grid sx={{ display: { xs: 'none', md: 'flex' } }} item xs={10} sm={10} md={10}>

              <VisibilitySensor onChange={(isVisible) => isVisible ? startStepsAnimation() : null } >

                <Grid container>
                  
                  <Grid item xs={12} sm={12} md={3}>
                    <div style={{marginTop: 84, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img id="step1img" style={{maxHeight: 80}} src={Step1} alt="step1" />
                    </div>
                    <div style={{height: 52}}></div>
                    <div>
                      <Typography variant="caption" display="block" align="center" style={{lineHeight: "20px", fontWeight: '700', color: '#777E90'}}>Step 1</Typography>
                      <div style={{height: 16}}></div>
                      <Typography variant="h6" align="center" style={{lineHeight: "24px", fontSize: "16px", fontWeight: '700', color: '#FCFCFD'}}>Create</Typography>
                      <Typography variant="subtitle2" align="center" style={{fontWeight: '400', lineHeight: '24px', margin: 15, color: '#F4F5F6'}}>Connect your web3 and start creating your custom escrows.</Typography>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <div style={{marginTop: 84, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img id="step2img" style={{maxHeight: 80}} src={Step2} alt="step2" />
                    </div>
                    <div style={{height: 52}}></div>
                    <div>
                      <Typography variant="caption" display="block" align="center" style={{lineHeight: "20px", fontWeight: '700', color: '#777E90'}}>Step 2</Typography>
                      <div style={{height: 16}}></div>
                      <Typography variant="h6" align="center" style={{lineHeight: "24px", fontSize: "16px", fontWeight: '700', color: '#FCFCFD'}}>Invite</Typography>
                      <Typography variant="subtitle2" align="center" style={{fontWeight: '400', lineHeight: '24px', margin: 15, color: '#F4F5F6'}}>Invite the opposing party to join your secure escrow.</Typography>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <div style={{marginTop: 84, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img id="step3img" style={{maxHeight: 80}} src={Step3} alt="step3" />
                    </div>
                    <div style={{height: 52}}></div>
                    <div>
                      <Typography variant="caption" display="block" align="center" style={{lineHeight: "20px", fontWeight: '700', color: '#777E90'}}>Step 3</Typography>
                      <div style={{height: 16}}></div>
                      <Typography variant="h6" align="center" style={{lineHeight: "24px", fontSize: "16px", fontWeight: '700', color: '#FCFCFD'}}>Confirm</Typography>
                      <Typography variant="subtitle2" align="center" style={{fontWeight: '400', lineHeight: '24px', margin: 15, color: '#F4F5F6'}}>Each party honors the terms of the agreement and confirms.</Typography>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <div style={{marginTop: 84, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img id="step4img" style={{maxHeight: 80}} src={Step4} alt="step4" />
                    </div>
                    <div style={{height: 52}}></div>
                    <div>
                      <Typography variant="caption" display="block" align="center" style={{lineHeight: "20px", fontWeight: '700', color: '#777E90'}}>Step 4</Typography>
                      <div style={{height: 16}}></div>
                      <Typography variant="h6" align="center" style={{lineHeight: "24px", fontSize: "16px", fontWeight: '700', color: '#FCFCFD'}}>Finalize</Typography>
                      <Typography variant="subtitle2" align="center" style={{fontWeight: '400',lineHeight: '24px', margin: 15, color: '#F4F5F6'}}>The escrow is finalized once each party has honored their part.</Typography>
                    </div>
                  </Grid>

                </Grid>

              </VisibilitySensor>
              
            </Grid>


            <Grid sx={{ display: { xs: 'flex', md: 'none' } }} item xs={10} sm={10} md={10}>

                <Grid container>

                  <VisibilitySensor onChange={(isVisible) => isVisible ? startStepsAnimation() : null } >

                  <Grid item xs={12} sm={12} md={3}>
                    <div style={{marginTop: 100}}>
                    </div>
                    <div>
                      <img id="step1img_" style={{position: 'absolute', maxHeight: 60}} src={Step1} alt="step1" />
                      <Typography variant="caption" display="block" align="center" style={{lineHeight: "20px", fontWeight: '700', color: '#777E90'}}>Step 1</Typography>
                      <div style={{height: 16}}></div>
                      <Typography variant="h6" align="center" style={{lineHeight: "24px", fontSize: "16px", fontWeight: '700', color: '#FCFCFD'}}>Create</Typography>
                      <Typography variant="subtitle2" align="center" style={{lineHeight: '24px', margin: 15, color: '#F4F5F6'}}>Connect your web3 and start creating your custom escrows.</Typography>
                    </div>
                  </Grid>

                  </VisibilitySensor>

                  <Grid item xs={12} sm={12} md={3}>
                    <div style={{display: 'flex', justifyContent: 'center', height: '160px', paddingBottom: 15}}>

                        <svg id="line1_" width="12" height="160" viewBox="0 0 12 160" fill="none" xmlns="http://www.w3.org/2000/svg">

                          <defs>
                            <mask id="msk_">
                              <rect width="100%" height="100%" fill="white" />
                              <rect fill="black" x="0" y="0" width="12" height="160" >
                                <animate id="an1_" attributeName="y" begin="indefinite" dur={(lineDuration/1000).toFixed(2)+"s"} values="0;320" repeatCount="0" fill="black" />
                              </rect>
                            </mask>
                          </defs>

                          <g id="path_">
                            <line x1="6.5" y1="17" x2="6.5" y2="140" stroke="#B1B5C4" strokeDasharray="4 8"/>
                            <circle cx="6" cy="154" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                            <circle cx="6" cy="6" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                          </g>
                          <g mask="url(#msk_)">
                            <line x1="6.5" y1="17" x2="6.5" y2="140" stroke="rgba(127,252,141,1)" strokeDasharray="4 8"/>
                            <circle cx="6" cy="154" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                            <circle cx="6" cy="6" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                          </g>

                        </svg>

                    </div>
                    <div>
                      <img id="step2img_" style={{position: 'absolute', maxHeight: 60}} src={Step2} alt="step2" />
                      <Typography variant="caption" display="block" align="center" style={{lineHeight: "20px", fontWeight: '700', color: '#777E90'}}>Step 2</Typography>
                      <div style={{height: 16}}></div>
                      <Typography variant="h6" align="center" style={{lineHeight: "24px", fontSize: "16px", fontWeight: '700', color: '#FCFCFD'}}>Invite</Typography>
                      <Typography variant="subtitle2" align="center" style={{lineHeight: '24px', margin: 15, color: '#F4F5F6'}}>Invite the opposing party to join your secure escrow.</Typography>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <div style={{display: 'flex', justifyContent: 'center', height: '160px', paddingBottom: 15}}>

                        <svg id="line2_" width="12" height="160" viewBox="0 0 12 160" fill="none" xmlns="http://www.w3.org/2000/svg">

                          <defs>
                            <mask id="msk2_">
                              <rect width="100%" height="100%" fill="white" />
                              <rect fill="black" x="0" y="0" width="12" height="160" >
                                <animate id="an2_" attributeName="y" begin="indefinite" dur={(lineDuration/1000).toFixed(2)+"s"} values="0;320" repeatCount="0" fill="black" />
                              </rect>
                            </mask>
                          </defs>

                          <g id="path2_">
                            <line x1="6.5" y1="17" x2="6.5" y2="140" stroke="#B1B5C4" strokeDasharray="4 8"/>
                            <circle cx="6" cy="154" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                            <circle cx="6" cy="6" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                          </g>
                          <g mask="url(#msk2_)">
                            <line x1="6.5" y1="17" x2="6.5" y2="140" stroke="rgba(127,252,141,1)" strokeDasharray="4 8"/>
                            <circle cx="6" cy="154" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                            <circle cx="6" cy="6" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                          </g>

                        </svg>

                    </div>
                    <div>
                      <img id="step3img_" style={{position: 'absolute', maxHeight: 60}} src={Step3} alt="step3" />
                      <Typography variant="caption" display="block" align="center" style={{lineHeight: "20px", fontWeight: '700', color: '#777E90'}}>Step 3</Typography>
                      <div style={{height: 16}}></div>
                      <Typography variant="h6" align="center" style={{lineHeight: "24px", fontSize: "16px", fontWeight: '700', color: '#FCFCFD'}}>Confirm</Typography>
                      <Typography variant="subtitle2" align="center" style={{lineHeight: '24px', margin: 15, color: '#F4F5F6'}}>Each party honors the terms of the agreement and confirms.</Typography>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <div style={{display: 'flex', justifyContent: 'center', height: '160px', paddingBottom: 15}}>

                        <svg id="line3_" width="12" height="160" viewBox="0 0 12 160" fill="none" xmlns="http://www.w3.org/2000/svg">

                          <defs>
                            <mask id="msk3_">
                              <rect width="100%" height="100%" fill="white" />
                              <rect fill="black" x="0" y="0" width="12" height="160" >
                                <animate id="an3_" attributeName="y" begin="indefinite" dur={(lineDuration/1000).toFixed(2)+"s"} values="0;320" repeatCount="0" fill="black" />
                              </rect>
                            </mask>
                          </defs>

                          <g id="path3_">
                            <line x1="6.5" y1="17" x2="6.5" y2="140" stroke="#B1B5C4" strokeDasharray="4 8"/>
                            <circle cx="6" cy="154" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                            <circle cx="6" cy="6" r="5" stroke="#B1B5C4" strokeWidth="2"/>
                          </g>
                          <g mask="url(#msk3_)">
                            <line x1="6.5" y1="17" x2="6.5" y2="140" stroke="rgba(127,252,141,1)" strokeDasharray="4 8"/>
                            <circle cx="6" cy="154" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                            <circle cx="6" cy="6" r="5" stroke="rgba(127,252,141,1)" strokeWidth="2"/>
                          </g>

                        </svg>

                    </div>
                    <div>
                      <img id="step4img_" style={{position: 'absolute', maxHeight: 60}} src={Step4} alt="step4" />
                      <Typography variant="caption" display="block" align="center" style={{lineHeight: "20px", fontWeight: '700', color: '#777E90'}}>Step 4</Typography>
                      <div style={{height: 16}}></div>
                      <Typography variant="h6" align="center" style={{lineHeight: "24px", fontSize: "16px", fontWeight: '700', color: '#FCFCFD'}}>Finalize</Typography>
                      <Typography variant="subtitle2" align="center" style={{lineHeight: '24px', margin: 15, color: '#F4F5F6'}}>The escrow is finalized once each party has honored their part.</Typography>
                    </div>
                  </Grid>

                </Grid>
              
            </Grid>


            <Grid item xs={1} sm={1} md={1}></Grid>

          </Grid>

          <Grid container>
            <div style={{height: 120}}></div>
          </Grid>

          <Grid style={{backgroundColor: '#18191D'}} container>
            <div style={{height: 60}}></div>
          </Grid>

          <Grid style={{backgroundColor: '#18191D'}} container>

            <Grid item xs={1} sm={1} md={12*100/1440}>
            </Grid>

            <Grid item xs={10} sm={10} md={12*1238/1440}>

              <Typography variant="caption" style={{fontSize: "13.5px", letterSpacing: "0.1px", lineHeight: '16px', fontWeight: '800'}}><span className="amazing_features_span">AMAZING FEATURES</span></Typography>
              <div style={{height: 16}}></div>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12*400/1240}>
                      <Typography variant="h3" style={{color: 'white'}}>See what you can do in one app</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12*235/1240}>
                    </Grid>
                    <Grid style={{display: 'flex', alignItems: 'center'}} item xs={12} sm={12} md={12*605/1240}>
                      <Typography variant="body2" style={{letterSpacing: "-0.02px", color: '#FFFFFF', lineHeight: '27px', fontWeight: '500'}}>A lot of useful features to make your activity easier for you and all users, we have thought of every detail to avoid you having to look elsewhere.</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid sx={{display: {xs: 'none', md: 'flex'}}} container>

                <Grid item xs={12} sm={12} md={12*392/1240}>

                  <div style={{marginTop: 56, backgroundColor: "#202227", borderRadius: 30, padding: 30, paddingRight: 24, paddingTop: 60,}}>
                    <img src={Sec4} alt="TradeIcon" />
                    <div style={{height: 25}}></div>
                    <Typography variant="h6" style={{letterSpacing: "-0.02px", lineHeight: "25.2px", fontWeight: '700', color: 'white'}}>Easily trade</Typography>
                    <div style={{height: 16}}></div>
                    <Typography variant="body2" style={{opacity: 0.5, letterSpacing: "-0.1px", color: '#FFFFFF', fontWeight: '500', lineHeight: '27px'}}>Middn allows users to trade easily and quickly, providing them with an optimal transaction experience.</Typography>
                  </div>

                </Grid>

                <Grid item xs={12} sm={12} md={12*(392+32*2)/1240}>
                  <Grid container>
                    <Grid item xs={0} sm={0} md={12*32/456}></Grid>
                    <Grid item xs={12} sm={12} md={12*(456-2*32)/456}>
                      <div style={{marginTop: 56, backgroundColor: "#202227", borderRadius: 30, padding: 30, paddingRight: 24, paddingTop: 60,}}>
                        <img src={Sec5} alt="TalkIcon" />
                        <div style={{height: 25}}></div>
                        <Typography variant="h6" style={{letterSpacing: "-0.02px", lineHeight: "25.2px", fontWeight: '700', color: 'white'}}>Talk in app</Typography>
                        <div style={{height: 16}}></div>
                        <Typography variant="body2" style={{opacity: 0.5, letterSpacing: "-0.1px", color: '#FFFFFF', fontWeight: '500', lineHeight: '27px'}}>Middn offers secure and private messaging that allows you to communicate confidently with your peers.</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={0} sm={0} md={12*32/456}></Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12*392/1240}>

                  <div style={{marginTop: 56, backgroundColor: "#202227", borderRadius: 30, padding: 30, paddingRight: 24, paddingTop: 60,}}>
                    <img src={Sec6} alt="SecureIcon" />
                    <div style={{height: 25}}></div>
                    <Typography variant="h6" style={{letterSpacing: "-0.02px", lineHeight: "25.2px", fontWeight: '700', color: 'white'}}>Have a profile</Typography>
                    <div style={{height: 16}}></div>
                    <Typography variant="body2" style={{opacity: 0.5, letterSpacing: "-0.1px", color: '#FFFFFF', fontWeight: '500', lineHeight: '27px'}}>Middn offers users the ability to customize their profile and benefit from reputation, statistics and more.</Typography>
                  </div>

                </Grid>

              </Grid>

              <Grid sx={{display: {xs: 'flex', md: 'none'}}} container>

              
                <Slider style={{maxWidth: '100%'}} {...settings}>

                  <div className="card__container">
                    <div className="card2" style={{marginLeft: 10, marginRight: 10, marginTop: 56, backgroundColor: "#202227", borderRadius: 30, padding: 30, paddingRight: 24, paddingTop: 60,}}>
                      <img src={Sec4} alt="TradeIcon" />
                      <div style={{height: 25}}></div>
                      <Typography variant="h6" style={{letterSpacing: "-0.02px", lineHeight: "25.2px", fontWeight: '700', color: 'white'}}>Easily trade</Typography>
                      <div style={{height: 16}}></div>
                      <Typography variant="body2" style={{opacity: 0.5, letterSpacing: "-0.1px", color: '#FFFFFF', fontWeight: '500', lineHeight: '27px'}}>Middn allows users to trade easily and quickly, providing them with an optimal transaction experience.</Typography>
                    </div>
                  </div>

                  <div className="card__container">
                    <div className="card2" style={{marginLeft: 10, marginRight: 10, marginTop: 56, backgroundColor: "#202227", borderRadius: 30, padding: 30, paddingRight: 24, paddingTop: 60,}}>
                      <img src={Sec5} alt="TalkIcon" />
                      <div style={{height: 25}}></div>
                      <Typography variant="h6" style={{letterSpacing: "-0.02px", lineHeight: "25.2px", fontWeight: '700', color: 'white'}}>Talk in app</Typography>
                      <div style={{height: 16}}></div>
                      <Typography variant="body2" style={{opacity: 0.5, letterSpacing: "-0.1px", color: '#FFFFFF', fontWeight: '500', lineHeight: '27px'}}>Middn offers secure and private messaging that allows you to communicate confidently with your peers.</Typography>
                    </div>
                  </div>

                  <div className="card__container">
                    <div className="card2" style={{marginLeft: 10, marginRight: 10, marginTop: 56, backgroundColor: "#202227", borderRadius: 30, padding: 30, paddingRight: 24, paddingTop: 60,}}>
                      <img src={Sec6} alt="SecureIcon" />
                      <div style={{height: 25}}></div>
                      <Typography variant="h6" style={{letterSpacing: "-0.02px", lineHeight: "25.2px", fontWeight: '700', color: 'white'}}>Have a profile</Typography>
                      <div style={{height: 16}}></div>
                      <Typography variant="body2" style={{opacity: 0.5, letterSpacing: "-0.1px", color: '#FFFFFF', fontWeight: '500', lineHeight: '27px'}}>Middn offers users the ability to customize their profile and benefit from reputation, statistics and more.</Typography>
                    </div>
                  </div>

                </Slider>

              </Grid>


              <div style={{height: 55}}></div>
              <VisibilitySensor onChange={(isVisible) => { gotNewSection(sections[2], isVisible) }} >
              <div ref={myRef3}  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <a href="#contact" className="gotoapp_button" style={{textDecoration: 'none'}}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold', color: '#131517'}}>Contact us</Typography>
                  </a>
              </div>
              </VisibilitySensor>
              <div style={{height: 60}}></div>

            </Grid>

            <Grid item xs={1} sm={1} md={12*100/1440}>
            </Grid>

          </Grid>






          <Grid container>
            <div style={{height: 137}}></div>
          </Grid>
          <Grid container>

            <Grid item xs={1} sm={1} md={12*100/1440}>
            </Grid>

            <Grid style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} item xs={10} sm={10} md={12*1238/1440}>

              <Grid container>

                <Grid item xs={0} sm={0} md={3}></Grid>
                <Grid style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} item xs={12} sm={12} md={6}>
                  <Typography variant="h3" align="center" style={{color: 'white', fontWeight: '500', lineHeight: '56px', letterSpacing: '-1px'}}>Secure your trades now !</Typography>
                </Grid>
                <Grid item xs={0} sm={0} md={3}></Grid>
                
              </Grid>


            </Grid>

            <Grid item xs={1} sm={1} md={1}>
            </Grid>

          </Grid>
          <Grid container>
            <div style={{height: 25}}></div>
          </Grid>
          <Grid container>

            <Grid item xs={1} sm={1} md={1}>
            </Grid>

            <Grid style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} item xs={10} sm={10} md={10}>

              <Grid container>

                <Grid item xs={0} sm={0} md={3}></Grid>
                <Grid style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} item xs={12} sm={12} md={6}>
                  <Typography variant="description" align="center" style={{color: '#6A7986', lineHeight: '24px', letterSpacing: '-0.02px'}}>Are you still not convinced? Use our service once and you will definetely come back for more.</Typography>
                </Grid>
                <Grid item xs={0} sm={0} md={3}></Grid>
                
              </Grid>

            </Grid>

            <Grid item xs={1} sm={1} md={12*100/1440}>
            </Grid>

          </Grid>

          <Grid container>
            <div style={{height: 25}}></div>
          </Grid>

          <Grid container>
            <Grid style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} item xs={12}>
              <a href="#try" className="gotoapp_button" style={{textDecoration: 'none'}}>
                <Typography variant="subtitle1" style={{fontWeight: 'bold', color: '#131517'}}>Try it free</Typography>
              </a>
            </Grid>
          </Grid>

          <Grid container>
            <div style={{height: 135}}></div>
          </Grid>

          <Grid style={{borderTop: "1px solid #2B2B2B", paddingBottom: 62}} container>

            <Grid item xs={12*136/1440}></Grid>
            <Grid item xs={12*(1440-2*136)/1440}>


              <Grid container>
                    
                <Grid item xs={12} sm={12} md={6}>

                  <Grid container>
                    
                    <Grid sx={{ textAlign: { xs: 'center', md: 'left' } }} item xs={6} sm={6} md={4}>
                      <div style={{ display: 'inline-block', textAlign: 'left'}}>
                        <Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "28.8px", color: 'white', marginTop: 40}}>Help Section</Typography>
                        <br />
                        <a style={{textDecoration: 'none'}} href="#faqs"><Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "20px", color: '#D0D4EA'}}>FAQs</Typography></a>
                        <br />
                        <a style={{textDecoration: 'none'}} href="#contact"><Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "20px", color: '#D0D4EA'}}>Contact US</Typography></a>
                        <br />
                        <a style={{textDecoration: 'none'}} href="#privacy"><Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "20px", color: '#D0D4EA'}}>Privacy Policy</Typography></a>
                      </div>
                    </Grid>
                    <Grid sx={{ textAlign: { xs: 'center', md: 'left' } }} item xs={6} sm={6} md={4}>
                      <div style={{display: 'inline-block', textAlign: 'left'}}>
                        <Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "28.8px", color: 'white', marginTop: 40}}>Partnership</Typography>
                        <br />
                        <a style={{textDecoration: 'none'}} href="#team"><Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "20px", color: '#D0D4EA'}}>Our Team</Typography></a>
                        <br />
                        <a style={{textDecoration: 'none'}} href="#partnership"><Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "20px", color: '#D0D4EA'}}>Partnership</Typography></a>
                        <br />
                        <a style={{textDecoration: 'none'}} href="https://middn.substack.com/"><Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "20px", color: '#D0D4EA'}}>Blog</Typography></a>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} sx={{ display: { xs: 'none', md: 'inline' } }}>
                      <Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "28.8px", color: 'white', marginTop: 40}}>Social Links</Typography>
                      <br />
                      <a style={{textDecoration: 'none'}} href="https://twitter.com/middn_com"><Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "20px", color: '#D0D4EA'}}>Twitter</Typography></a>
                      <br />
                      <a style={{textDecoration: 'none'}} href="#facebook"><Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "20px", color: '#D0D4EA'}}>Facebook</Typography></a>
                      <br />
                      <a style={{textDecoration: 'none'}} href="#telegram"><Typography variant="body1" style={{fontFamily: "Inter", fontWeight: "500", lineHeight: "20px", color: '#D0D4EA'}}>Telegram</Typography></a>
                    </Grid>
                  </Grid>
                  
                </Grid>

                <Grid item xs={12} sm={12} md={6}>

                  <Grid container>
                    

                    <Grid item xs={12} sm={12} md={12}>
                      
                      <Typography sx={{ textAlign: { xs: 'center', md: 'right' }, marginTop: { xs: '65px', md: '36px' } }} variant="body1" style={{letterSpacing: "-0.02px", lineHeight: "28.8px", color: 'white', fontWeight: '500'}}>Receive latest news</Typography>

                      <Grid style={{marginTop: 31}} container>
                        
                        <Grid sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'right' }, marginBottom: { xs: '31px', md: '20px' } } } item xs={12} sm={12} md={12*(584-165)/584}>
                          
                          <input style={{width: 200}} className="email_input" type="email" name="email" placeholder="Enter your e-mail address" />

                        </Grid>

                        <Grid sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'right' }, marginBottom: { xs: '0px', md: '20px' } }} item xs={12} sm={12} md={12*(165)/584}>
                          <a href="#start" className="gotoapp_button" style={{textDecoration: 'none'}}>
                            <Typography align="center" variant="subtitle1" style={{fontWeight: 'bold', color: '#131517'}}>Get Started</Typography>
                          </a>
                        </Grid>

                      </Grid>

                    </Grid>

                  </Grid>
                  
                </Grid>

              </Grid>

              <Grid container>
                    
                <Grid item xs={12} sm={12} md={6}></Grid>

                <Grid item xs={12} sm={12} md={6}>

                  <Grid container>
                    
                    <Grid item xs={6*(1-252/304)} sm={6*(1-252/304)} md={3}></Grid>

                    <Grid item xs={12*252/304} sm={12*252/304} md={9}>
                      <Typography sx={{ paddingTop: { xs: '31px', md: '32px' }, textAlign: { xs: 'justify', md: 'right' } }} variant="body1" style={{fontSize: "15.5px", color: '#BEC1CE', fontWeight: '500', lineHeight: '20px'}}>*by entering your e-mail address you confirming that you're agree to subscribe to our newsletter.</Typography>
                    </Grid>

                    <Grid item xs={6*(1-252/304)} sm={6*(1-252/304)} md={0}></Grid>


                  </Grid>
                  
                </Grid>

              </Grid>


            </Grid>

            <Grid item xs={12*136/1440}></Grid>


          </Grid>


          <Grid style={{backgroundColor: '#16181A', paddingBottom: 20, paddingTop: 20}} container>

            <Grid item xs={12*132.5/1440}></Grid>
            <Grid item xs={12*(1440-2*132.5)/1440}>
              <Grid container>
                <Grid sx={{ textAlign: { xs: 'center', md: 'left' } }} item xs={12} sm={12} md={6}>
                  <img src={Logo} alt="MiddnLogo"/>
                </Grid>
                <Grid  sx={{ textAlign: { xs: 'center', md: 'right' } }} item xs={12} sm={12} md={6}>
                  <Typography  variant="caption" style={{color: '#777E90', fontFamily: "Poppins", lineHeight: '20px', fontWeight: '400', fontSize: 11}}>Copyright  &copy; 2022 Middn. All rights reserved.</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12*132.5/1440}></Grid>

          </Grid>


        </div>

    	</ThemeProvider>
    </div>
	);
};




